$env: production;
@import '@/styles/_component-base.styles.scss';

.backgroundImage {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    height: 516px;

    @include breakpoint(md) {
        height: 492px;
    }

    @include breakpoint(lg) {
        height: 460px;
    }
}
