$env: production;
@import '@/styles/_component-base.styles.scss';

.baseLink {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 75%;
    max-height: 40px;
    padding: spacer(sm) spacer(xs);
    font-weight: normal;

    &:hover {
        opacity: 0.85;
    }

    @include breakpoint(md) {
        padding: spacer(sm) spacer(base);
        font-size: set-fontSize(18);
    }

    @include breakpoint(lg) {
        padding: spacer(sm) spacer(base);
        font-size: set-fontSize(20);
    }
}

.homeHero {
    position: relative;
    height: 350px;

    @include breakpoint(sm) {
        height: 500px;
    }

    @include breakpoint(lg) {
        height: calc(100vw * 0.33);
    }

    &V1 {
        height: 500px;

        @include breakpoint(2xl) {
            height: 600px;
        }

        @media only screen and (min-width: 2560px) {
            height: 700px;
        }
    }

    .heading {
        width: 100%;
        font-size: set-fontSize(28);
        text-align: center;
        color: color(contrast, white);
        padding-top: spacer(sm);

        @include breakpoint(md) {
            padding-top: spacer(2xl);
            font-size: set-fontSize(36);
        }

        @include breakpoint(lg) {
            font-size: set-fontSize(40);
        }

        &V1 {
            font-size: set-fontSize(32);
            padding-top: 0;

            @include breakpoint(md) {
                font-size: set-fontSize(40);
            }

            @include breakpoint(2xl) {
                font-size: set-fontSize(44);
            }
        }
    }

    .description {
        padding: 0;
        padding-top: spacer(xs);
        text-align: center;
        display: block;
        color: color(contrast, white);
        font-size: set-fontSize(16);

        @include breakpoint(md) {
            padding-top: spacer(base);
        }

        @include breakpoint(lg) {
            font-size: set-fontSize(20);
        }

        &V1 {
            padding-left: spacer(xs);
            padding-right: spacer(xs);

            @include breakpoint(lg) {
                padding-top: spacer(xs);
            }

            @include breakpoint(2xl) {
                font-size: set-fontSize(24);
            }
        }
    }

    .heroContainer {
        position: relative;
        height: 100%;
        width: 100%;

        &V1 {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding-top: spacer(3xl);

            @include breakpoint(md) {
                padding-top: spacer(5xl);
            }

            @include breakpoint(2xl) {
                padding-top: spacer(7xl);
            }

        }
    }

    .verticalDivider {
        border-left: 1px solid color(contrast, white);
        margin: 0 spacer(lg);
        height: 65px;
    }

    .ctaList {
        display: grid;
        gap: 15px;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: spacer(3xs);

        @include breakpoint(md) {
            display: flex;
            gap: 0px;
            align-items: center;
            justify-content: center;
            margin-top: spacer(base);
        }

        &V1 {
            margin-top: 0;
            padding-top: spacer(base);

            @include breakpoint(md) {
                padding-top: spacer(md) + spacer(3xs);
            }

            @include breakpoint(lg) {
                padding-top: spacer(xs);
            }
        }
    }

    .button {
        @extend .baseLink;
        color: color(contrast, white) !important;
        background-color: color(accent, blue);
        font-size: set-fontSize(16);
        line-height: normal;
        height: 40px;
        width: 199px;

        &V1 {
            @include breakpoint(md) {
                margin-right: spacer(3xl);
            }
            @include breakpoint(2xl) {
                font-size: set-fontSize(20);
                width: 250px;
            }
        }
    }

    .link {
        @extend .baseLink;
        text-decoration: underline;
        font-family: $fontFamily-serif;

        &--white {
            color: color(contrast, white) !important;
        }

        &--black {
            color: color(text, base) !important;
        }
    }

    .bottomLink {
        text-decoration: underline;
        position: absolute;
        bottom: 0;
        left: 0;
        font-weight: normal;
        padding: spacer(sm);
    }

    .linkIcon {
        stroke-width: 4px;
        margin-left: spacer(2xs);
        height: 10px;
        width: 10px;

        @include breakpoint(md) {
            height: 14px;
        }

        &--white {
            stroke: color(contrast, white);
        }

        &--black {
            stroke: color(text, base);
        }
    }
}
