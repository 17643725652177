$env: production;
@import "@/styles/_component-base.styles.scss";

.loader {
    position: relative;
}

div.productTileContainer {
    @include between-breakpoint(sm, md) {
        flex-basis: calc(4 / 12 * 100%);
        max-width: calc(4 / 12 * 100%);
    }
}

.sliderSection {
    padding-top: 0;
    padding-bottom: 0;

    @include only-breakpoint(xs) {
        padding-left: spacer(lg);
        padding-right: spacer(lg);
    }

    @include breakpoint(md) {
        display: none;
    }
}
